import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class User {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  register({ email, password }) {
    return this.api.post("/register", { email, password });
  }

  me() {
    return this.api.get("/me");
  }
  listSubUsers(body) {
    return this.api.get("/listSubUsers", body);
  }

  /**
   * { subUserId, ...updateFields }
   */
  editSubUser({ subUserId, ...updateFields }) {
    return this.api.put("/editSubUser", { subUserId, ...updateFields });
  }
  deleteSubUser({ subUserId }) {
    return this.api.delete("/deleteSubUser", { data: { subUserId } });
  }
  setSubuserActivation({ subUserId, accountActivated }) {
    return this.api.put("/setSubuserActivation", {
      subUserId,
      accountActivated,
    });
  }
  setFullClientAccess({ subUserId, hasFullClientAccess }) {
    return this.api.put("/setFullClientAccess", {
      subUserId,
      hasFullClientAccess,
    });
  }
  listClients() {
    return this.api.get("/listClients");
  }

  /**
   * { clientId, ...updateFields }
   */
  editClient(body) {
    return this.api.put("/editClient", body);
  }
  deleteClient({ clientId }) {
    return this.api.delete("/deleteClient", { data: { clientId } });
  }
  getNotifications({ page, limit }) {
    return this.api.get(`/getNotifications?page=${page}&limit=${limit}`);
  }
  changeNotificationSetting({
    createClientGroup,
    createClient,
    editClientGroup,
    editClient,
    deleteClientGroup,
    deleteClient,
  }) {
    return this.api.put("/changeNotificationSetting", {
      notifications: {
        createClientGroup,
        createClient,
        editClientGroup,
        editClient,
        deleteClientGroup,
        deleteClient,
      },
    });
  }
  changePassword({ oldPassword, newPassword }) {
    return this.api.put("/changePassword", { oldPassword, newPassword });
  }
  changeContact({ email, mobile }) {
    return this.api.put("/changeContact", { email, mobile });
  }

  getLimit() {
    return this.api.get("/getLimit");
  }
  googleSearch({ query, limit, related_keywords }) {
    return this.api.post("/googleSearch", { query, limit, related_keywords });
  }
  searchResultStore(searchData) {
    return this.api.post("/searchResult", searchData);
  }
  setLimit({ limit }) {
    return this.api.put("/setLimit", { limit });
  }
  handleSubscribe(data) {
    return this.api.post("/handleSubscribe", data);
  }
  handleActivateScreening(data) {
    return this.api.post("/handleActivateScreening", data);
  }
  handleUpgrade({ userId, newLimit }) {
    return this.api.post("/handleUpgrade", { userId, newLimit });
  }
  updateProfile(body) {
    return this.api.put("/updateProfile", body);
  }
  getNotificationSettings() {
    return this.api.get("/getnotificationsettings");
  }
  readnotification(id) {
    return this.api.put("/readnotification/" + id);
  }
  dashboardDetails(id) {
    return this.api.get("/dashboardetails/");
  }
  createSupportTicket({ type, message }) {
    return this.api.post("/createSupportTicket", { type, message });
  }
  search({ text }) {
    return this.api.post("/search", { text });
  }
  getScreeningReport() {
    return this.api.get(`/getScreeningReport`);
  }
  getClient() {
    return this.api.get("/getClient");
  }
}

export default new User(`${getBackendUrl()}/user`);
