import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Public from "../service/Public";
import Video from "../assets/img/synergy_link_video.mp4";
import videoThamb from "../assets/img/video_thamb.png";

const Landing = () => {
  const [api, contextHolder] = notification.useNotification();
  const [mobileMenu, setMobileMenu] = useState(false);

  const [heroImage, setHeroImage] = useState(localStorage.heroImage ?? null);
  const [heroImage2, setHeroImage2] = useState(localStorage.heroImage2 ?? null);
  const [heroImage3, setHeroImage3] = useState(localStorage.heroImage3 ?? null);
  const [heroImage4, setHeroImage4] = useState(localStorage.heroImage4 ?? null);
  const [heroImage5, setHeroImage5] = useState(localStorage.heroImage5 ?? null);

  const [subscriptions, setSubscriptions] = useState(
    localStorage.subscriptions ?? null
  );
  const [FAQs, setFAQs] = useState(localStorage.FAQs ?? null);

  const loadDataPricing = useCallback(async () => {
    const data = await Public.getSubscriptionPackages();
    if (data.data.subscriptions) {
      setSubscriptions(data.data.subscriptions);
      localStorage.setItem("subscriptions", data.data.subscriptions);
    }
  }, []);
  const loadDataFAQ = useCallback(async () => {
    const data = await Public.getFAQs();
    if (data.data.FAQs) {
      // console.log("FAQSSSSS", data);
      setFAQs(data.data.FAQs);
      localStorage.setItem("FAQs", data.data.FAQs);
    }
  }, []);

  const loadData = async () => {
    const data = await Public.getHeroImage("heroImage");
    if (data.data.heroImage) {
      setHeroImage(data.data.heroImage);
      localStorage.setItem("heroImage", data.data.heroImage);
    }
  };
  const loadData2 = async () => {
    const data = await Public.getHeroImage("heroImage2");
    if (data.data.heroImage2) {
      setHeroImage2(data.data.heroImage2);
      localStorage.setItem("heroImage2", data.data.heroImage2);
    }
  };
  const loadData3 = async () => {
    const data = await Public.getHeroImage("heroImage3");
    if (data.data.heroImage3) {
      setHeroImage3(data.data.heroImage3);
      localStorage.setItem("heroImage3", data.data.heroImage3);
    }
  };
  const loadData4 = async () => {
    const data = await Public.getHeroImage("heroImage4");
    if (data.data.heroImage4) {
      setHeroImage4(data.data.heroImage4);
      localStorage.setItem("heroImage4", data.data.heroImage4);
    }
  };
  const loadData5 = async () => {
    const data = await Public.getHeroImage("heroImage5");
    if (data.data.heroImage5) {
      setHeroImage5(data.data.heroImage5);
      localStorage.setItem("heroImage5", data.data.heroImage5);
    }
  };

  useEffect(() => {
    loadData();
    loadData2();
    loadData3();
    loadData4();
    loadData5();
    loadDataPricing();
    loadDataFAQ();
  }, [loadDataPricing]);

  const openNotification = (placement) => {
    api.success({
      message: `Would you like to test our solution?`,
      description: (
        <div className="landing-wrapper">
          <div>
            <a href="#" className="button cc-small w-button">
              Book a demo
            </a>
          </div>
        </div>
      ),
      placement,
      duration: 0,
    });
  };

  // useEffect(() => {
  //   setTimeout(() => openNotification("bottomRight"), 60 * 1000); // open demo booking popup after 60 seconds on the page
  // }, []);

  return (
    <div className="landing-wrapper">
      {contextHolder}
      <div>
        <div className="navbar wf-section">
          <div className="navbar-space" />
          <div
            data-animation="default"
            data-collapse="medium"
            data-duration={400}
            data-easing="ease-out"
            data-easing2="ease-out"
            role="banner"
            className="navbar-inner w-nav"
          >
            <div className="container">
              <div className="navbar-wrapper">
                <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                  <div className="nav-menu-flex">
                    <Link to="/" className="w-nav-brand">
                      <img
                        src="/images/Logo_horizontal.png"
                        loading="lazy"
                        height
                        alt=""
                        width={357}
                        srcSet="/images/Logo_horizontal-p-500.png 500w, images/Logo_horizontal-p-800.png 800w, images/Logo_horizontal-p-1080.png 1080w, images/Logo_horizontal-p-1600.png 1600w, images/Logo_horizontal-p-2000.png 2000w, images/Logo_horizontal-p-2600.png 2600w, images/Logo_horizontal.png 3056w"
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 203.8715362548828px, (max-width: 1279px) 21vw, 18vw"
                        className="logo"
                      />
                    </Link>
                    <ul role="list" className="nav-menu w-list-unstyled">
                      <li>
                        <a href="#home" className="nav-link">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#process" className="nav-link">
                          About
                        </a>
                      </li>
                      <li>
                        <a href="#howitworks" className="nav-link">
                          How it works
                        </a>
                      </li>
                      <li>
                        <a href="#pricing" className="nav-link">
                          Pricing
                        </a>
                      </li>
                    </ul>
                    <Link to="/auth/login" style={{ marginRight: 20 }}>
                      <img
                        src="/images/Frame-10.png"
                        loading="lazy"
                        width={45}
                        alt=""
                        className="image-2"
                      />
                    </Link>
                    <div className="navbar-button-wrapper">
                      <Link to="/contact" className="button cc-small w-button">
                        Say Hello
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease-out"
            data-easing2="ease-out"
            data-w-id="577246c2-be80-9a0d-4fe9-018c566815d9"
            role="banner"
            className="navbar-inner-mobile w-nav"
          >
            <div className="container">
              <div className="navbar-wrapper-mobile">
                <Link
                  to="/"
                  aria-current="page"
                  className="navbar-brand-mobile w-nav-brand w--current"
                >
                  <img
                    src="/images/Logo_horizontal.png"
                    loading="lazy"
                    height
                    width="119.5"
                    alt="flux"
                    className="logo"
                  />
                </Link>
                <div className="flex items-center">
                  <Link to="/auth/login" style={{ marginRight: 20 }}>
                    <img
                      src="/images/Frame-10.png"
                      loading="lazy"
                      width={45}
                      alt=""
                      className="image-2"
                    />
                  </Link>
                  <div
                    data-w-id="577246c2-be80-9a0d-4fe9-018c566815de"
                    className="menu-button w-nav-button"
                    onClick={() => setMobileMenu((e) => !e)}
                  >
                    <div className="burger">
                      <div className="burger-line cc-top" />
                      <div className="burger-line cc-middle" />
                      <div className="burger-line cc-bottom" />
                    </div>
                  </div>
                </div>
              </div>
              <nav
                role="navigation"
                className="nav-menu-wrapper-mobile w-nav-menu"
                style={{ display: mobileMenu ? "unset" : "none" }}
              >
                <div className="nav-menu-flex-mobile">
                  <ul role="list" className="nav-menu w-list-unstyled">
                    <li>
                      <a
                        href="#home"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="#process"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="#howitworks"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        How it works
                      </a>
                    </li>
                    <li>
                      <a
                        href="#pricing"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="navbar-shadow" />
          <div className="navbar-space" />
        </div>
        <section
          id="home"
          className="section background-primary-soft wf-section"
        >
          <div className="hero">
            <div className="hero-image-wrapper">
              <div className="div-block-2" />
              {heroImage && (
                <img
                  src={heroImage}
                  loading="lazy"
                  width={1000}
                  sizes="(max-width: 991px) 100vw, 50vw"
                  alt=""
                  className="hero-image"
                />
              )}
            </div>
            <div className="container">
              <div className="hero-grid">
                <div
                  id="w-node-dc678c3f-8d8f-00ba-7466-144a35cd5f25-9c4ebe05"
                  className="hero-content"
                >
                  <h1
                    data-w-id="5cd846b7-aae4-71b2-ca7a-70d8b7616be7"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="display-1 margin-bottom-20"
                  >
                    Welcome to Synrgy
                    <span className="text-gradient text-emphasize nonitalic">
                      Screen
                    </span>
                    <br />
                    Your Powerful{" "}
                    <span className="text-gradient text-emphasize">
                      sanctions screening{" "}
                    </span>
                    software
                  </h1>
                  <br />
                  <p
                    data-w-id="aeeed16e-bfc0-1466-2017-39da55aff322"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="paragraph-large"
                  >
                    Simplifying the complex world of sanctions screening.
                  </p>
                  <div
                    data-w-id="9a004d67-26d4-a27d-f78c-0ec4169fc242"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                  >
                    <Link
                      Link
                      to="/auth/login"
                      className="button cc-large w-button"
                    >
                      Get Started
                    </Link>
                  </div>
                  <img
                    src="/images/doodle2.svg"
                    loading="lazy"
                    height={70}
                    data-w-id="62c94f9a-5b09-7cb4-88d8-b76cbdfdc2cc"
                    alt=""
                    className="doodle-arrow"
                  />
                  <img
                    src="/images/doodle1.svg"
                    loading="lazy"
                    height={45}
                    data-w-id="c1f648b3-2bae-f1cd-1a4e-1cfa0c938230"
                    alt=""
                    className="doodle-shine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="process" className="section background-light wf-section">
          <div className="container">
            <div className="reasons">
              <div className="headline-reasons">
                <h3
                  data-w-id="dcff6d7c-9a88-842e-cff4-8eadd2d13368"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  }}
                  className="label text-secondary"
                >
                  Why Choose Us?
                </h3>
                <h2
                  data-w-id="8a5cd9a5-27e3-6133-dcf7-1c166a60f511"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  }}
                  className="display-2"
                >
                  <strong>Improve</strong>{" "}
                  <span className="text-gradient text-emphasize">
                    the accuracy and efficiency of your sanctions
                  </span>{" "}
                  screening with SynrgyScreen
                </h2>
              </div>

              <div>
                <div
                  data-w-id="7ddfe38d-e9d5-0a7a-c508-ae4044a55f76"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  }}
                  data-current="Tab 1"
                  data-easing="ease"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="w-tabs"
                >
                  <div className="tabs-menu w-tab-menu">
                    <a
                      data-w-tab="Tab 1"
                      className="tab card w-inline-block w-tab-link w--current"
                    >
                      <div className="step text-gradient">01</div>
                      <div className="tab-inner">
                        <h3>Expansive Data Sets</h3>
                        <div className="body-color">
                        SynrgyScreen includes screening of major sanctions lists including HMT, OFAC, EU, UN and public domain searches.
                        </div>
                      </div>
                    </a>
                    <a
                      data-w-tab="Tab 2"
                      className="tab card w-inline-block w-tab-link"
                    >
                      <div className="step text-gradient">02</div>
                      <div className="tab-inner">
                        <h3>Simplify</h3>
                        <div className="body-color">
                        Simplify your ongoing screening with our bulk upload features, allowing bulk uploads of client data and related parties. Bulk mark positive matches and false positives.
                        </div>
                      </div>
                    </a>
                    <a
                      data-w-tab="Tab 3"
                      className="tab card w-inline-block w-tab-link"
                    >
                      <div className="step text-gradient">03</div>
                      <div className="tab-inner">
                        <h3>Inclusive Searches</h3>
                        <div className="body-color">
                        Perform one-off searches for clients against our expansive sanctions lists and public domain searches. Flag and download your results.
                        </div>
                      </div>
                    </a>
                    <a
                      data-w-tab="Tab 3"
                      className="tab card w-inline-block w-tab-link"
                    >
                      <div className="step text-gradient">04</div>
                      <div className="tab-inner">
                        <h3>Reportable</h3>
                        <div className="body-color">
                          Access your reports at the click of a button. No more
                          lengthy report compilation processes.
                        </div>
                      </div>
                    </a>
                  </div>
                  {
                    //   <div className="tabs-content w-tab-content">
                    //   <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                    //     <div className="tab-pane-grid">
                    //       <div id="w-node-_33d5ff0a-a9ee-1551-3986-b35178efb1e7-9c4ebe05">
                    //         {heroImage2 && (
                    //           <img
                    //             src={heroImage2}
                    //             loading="lazy"
                    //             width={1000}
                    //             sizes="(max-width: 479px) 92vw, (max-width: 991px) 87vw, 43vw"
                    //             alt=""
                    //             className="rounded shadow"
                    //           />
                    //         )}
                    //       </div>
                    //       <div id="w-node-b791cf82-82b9-7055-7e04-4f63f43e2f91-9c4ebe05">
                    //         <h2>Inclusive Sanctions Screening Tool</h2>
                    //         <p>Enjoy a tool that is easy to use and inclusive.</p>
                    //         <div className="bullet-list margin-bottom-30">
                    //           <div className="bullet-list-item">
                    //             <div className="bullet">
                    //               <div className="unicon"></div>
                    //             </div>
                    //             <div>
                    //               Enhance your approach to customer sanctions
                    //               screening across global watchlists
                    //             </div>
                    //           </div>
                    //           <div className="bullet-list-item">
                    //             <div className="bullet">
                    //               <div className="unicon"></div>
                    //             </div>
                    //             <div>
                    //               Enjoy a Tool that is easy to use and comes with
                    //               bonus features including passport reader and
                    //               public searches
                    //             </div>
                    //           </div>
                    //           <div className="bullet-list-item">
                    //             <div className="bullet">
                    //               <div className="unicon"></div>
                    //             </div>
                    //             <div>
                    //               Protect your organization from non-compliance with
                    //               a more efficient way to identify and report on
                    //               matches{" "}
                    //             </div>
                    //           </div>
                    //         </div>
                    //         <Link to="/contact" className="button w-button">
                    //           Say Hello
                    //         </Link>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="howitworks"
          className="section background-light wf-section"
        >
          <div className="container">
            <div className="services-grid">
              {" "}
              <div
                id="w-node-_72b8af2d-5524-9ad8-95e6-936ff7033a26-9c4ebe05"
                data-w-id="72b8af2d-5524-9ad8-95e6-936ff7033a26"
                // style={{
                //   WebkitTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   MozTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   msTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   transform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                // }}
                className="sticky"
              >
                <h3 className="label text-secondary">
                  HOW&nbsp;IT&nbsp;WORKS?
                </h3>
                <h2 className="display-2 margin-bottom-25">
                  SynrgyScreen is an {""}
                  <span className="text-gradient text-emphasize">
                    easy-to-use screening tool,
                  </span>{" "}
                  enabling you to assess your customer's {""}
                  <span className="text-gradient text-emphasize">
                    sanctions risk
                  </span>{" "}
                  exposure
                </h2>
                <p className="margin-bottom-35">
                Centralise your screening processes with our
                comprehensive and automated means of mitigating
                sanctions risks.
                </p>
                <Link to="/contact" className="button w-button">
                  Say Hello
                </Link>
              </div>
              <div id="w-node-e936af14-d0d6-3191-8d17-d905eda72cd8-9c4ebe05">
                <div>
                  <h2>
                    <strong>
                      A bespoke platform with expansive screening options
                    </strong>
                  </h2>
                  <div className="bullet-list margin-bottom-30">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Intuitive and user-friendly dashboard</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Built in alert management so you never miss a sanction
                        notification
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Integration with all valid sanctions lists </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Additional searches on public domain websites</div>
                    </div>{" "}
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Significant reduction on manual processes</div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Cost-effective, flat fee – no hidden costs</div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2>
                    <strong>Technical features</strong>
                  </h2>
                  <div className="bullet-list margin-bottom-30">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Know your customer - receive notifications of sanctions hits
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Perform occasional one-off checks </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                       Place customers and related parties into
                       ongoing screening and receive alerts on new
                       hits, and changes in current hits
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                      Search the public domain internet in app, for
                      adverse media. Flag and download relevant
                      links for future reference
                      </div>
                    </div>
                    {/* <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Search the public domain internet in app for adverse
                        media.
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section video_section">
          <div className="container">
            <h2 className="text-center display-2 margin-bottom-25">Welcome to SynrgyScreen</h2>
            <video id="bannerVideo" loop controls poster={videoThamb} src={Video}>
              <source src={Video} type="video/mp4" />
            </video>
          </div>
        </section>

        <section
          id="pricing"
          className="section background-primary-soft wf-section"
        >
          <div className="container">
            <div className="pricing">
              <div
                data-w-id="6fdff87f-c3f4-a642-41fe-21e992ffafd8"
                // style={{
                //   WebkitTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   MozTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   msTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   transform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                // }}
                className="headline-pricing"
              >
                <h3 className="label text-secondary">Pricing</h3>
                <h2 className="display-2">
                  SynrgyScreen was designed as a cost-effective platform,
                  accessible to all sizes of business.
                  <span className="text-gradient text-emphasize">
                    Our fees are simple and transparent
                  </span>{" "}
                  No hidden add-ons. Simply sign up to the package best suited
                  to your needs.
                </h2>
              </div>
              <div
                data-w-id="53334cf9-df90-50d4-4a1f-ea55c60f3cb3"
                // style={{
                //   WebkitTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   MozTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   msTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   transform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                // }}
                className="prices-grid"
              >
                {subscriptions?.map?.((subscription) => (
                  <div
                    id="w-node-_53334cf9-df90-50d4-4a1f-ea55c60f3ce7-9c4ebe05"
                    className="pricing-card card shadow cc-larger"
                    key={subscription.key}
                  >
                    <div className="pricing-card-inner">
                      <h3 className="price">
                        <span className="price-currency">$</span>
                        {subscription.price}
                        <span className="price-duration">/year&amp;plan</span>
                      </h3>
                      <h4 className="margin-bottom-30">{subscription.title}</h4>
                      <div className="bullet-list margin-bottom-30">
                        <div className="bullet-list-item">
                          <div className="bullet">
                            <div className="unicon"></div>
                          </div>
                          <div>
                            <span className="bold-text">
                              {subscription.limit}
                            </span>{" "}
                            client screenings
                          </div>
                        </div>
                      </div>
                      <Link
                        to="/auth/register"
                        className="button subbutton w-button"
                      >
                        Subscribe Now
                      </Link>
                    </div>
                  </div>
                ))}

                <div
                  id="w-node-_42f2fc2c-4869-fde7-ee97-ac54a00d1e19-9c4ebe05"
                  className="pricing-card card shadow"
                >
                  <div className="pricing-card-inner">
                    <h3 className="price">Custom</h3>
                    <div className="bullet-list margin-bottom-30">
                      <div className="bullet-list-item">
                        <div className="bullet">
                          <div className="unicon"></div>
                        </div>
                        <div className="text-block-9">
                          <span className="bold-text">Contact us</span>{" "}
                          <span>for a Quote</span>
                        </div>
                      </div>
                    </div>
                    <Link to="/contact" className="button subbutton w-button">
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>

              <div
                data-w-id="d0f6ad60-6b32-56f5-bb7b-d2285ef547bc"
                // style={{
                //   WebkitTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   MozTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   msTransform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                //   transform:
                //     "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                // }}
                className="faq-wrapper"
              >
                {FAQs?.map?.((faq) => (
                  <div
                    id="w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547cd-9c4ebe05"
                    className="faq-item"
                    key={faq.key}
                  >
                    <div className="faq-item-inner">
                      <div className="faq-icon">
                        <div className="unicon"></div>
                      </div>
                      <div className="faq">
                        <h3>
                          <strong className="bold-text-9">
                            {faq.question}
                          </strong>
                        </h3>
                        <div className="text-block-7">{faq.answer}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="relative">
            <div
              className="bg-cover bg-center"
              style={{
                backgroundColor: "rgba(101, 104, 169, 0.2)",
                height: "25rem",
              }}
            ></div>
            <div className="absolute inset-0   flex justify-center items-center">
              <div className="bg-white p-8 rounded-lg shadow-lg backdrop-blur-md">
                <h2 className="text-2xl font-semibold mb-4">
                  Need assistance with migrating and remediating your client
                  files?
                </h2>
                <p className="mb-4">
                  Let our experienced compliance consultants help!{" "}
                  <a href="mailto:info@synrgy.solutions">Email us</a> to speak
                  about our competitive rates.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="footer background-dark">
          <div className="container">
            <div className="footer-cta">
              <div className="join">
                <h2 className="display-3 text-white">
                  Start your AML journey today!
                </h2>
              </div>
              <div className="div-block">
                <Link to="/contact" className="button footerbutton w-button">
                  Say Hello
                </Link>
              </div>
            </div>
            <div className="divider" />
            <div className="footer-grid">
              <div>
                <img
                  src="/images/Logo_horizontal_white.png"
                  loading="lazy"
                  height
                  alt=""
                  width="220"
                  // srcSet="/images/Logo_stacked_white-p-500.png 500w, images/Logo_stacked_white-p-800.png 800w, images/Logo_stacked_white-p-1080.png 1080w, images/Logo_stacked_white-p-1600.png 1600w, images/Logo_stacked_white.png 1877w"
                  sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                  className="logo margin-bottom-20"
                />
                <div className="margin-bottom-20">
                  © {new Date().getFullYear()} Synrgy Solutions.
                </div>
                <div className="social-links-wrapper">
                  <ul role="list" className="social-links w-list-unstyled">
                    <li className="social-link-item">
                      <a
                        href="https://www.linkedin.com/company/synrgyaml/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-link w-inline-block"
                      >
                        <div className="unicon"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7">
                <div className="address margin-bottom-20">Get In Touch</div>
                <a
                  href="mailto:info@synrgy.solutions"
                  className="link-hover link-white"
                >
                  <strong>info@synrgy.solutions</strong>
                </a>
              </div>
              <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7">
                <ul role="list" className="unordered-list margin-bottom-none">
                  <li>
                    <a href="#process" className="link-hover link-white">
                      About
                    </a>
                  </li>
                  <li>
                    <Link to="/legal/privacy" className="link-hover link-white">
                      Legals
                    </Link>
                  </li>
                  <li>
                    <a href="#howitworks" className="link-hover link-white">
                      How it works
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" className="link-hover link-white">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <Link to="/contact" className="link-hover link-white">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth/login" className="link-hover link-white">
                      Platform
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
