import { LinkOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Input,
  List,
  Modal,
  Pagination,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import moment from "moment/moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useCallback, useEffect, useState } from "react";
import Public from "../../../service/Public";
import User from "../../../service/User";
import { getNetworkGraphData } from "../../../service/wikidata";
import DynamicDescription from "./DynamicDescription";
import { NetworkGraph } from "./NetworkGraph";
import { countries } from "./countries";
import { BsFillFlagFill } from "react-icons/bs";
import { IoFlagOutline } from "react-icons/io5";

const { Title, Text } = Typography;

const ENTITY_TYPES = [
  { value: "Address", label: "Address" },
  { value: "Airplane", label: "Airplane" },
  { value: "Associate", label: "Associate" },
  { value: "BankAccount", label: "BankAccount" },
  { value: "Company", label: "Company" },
  { value: "CryptoWallet", label: "CryptoWallet" },
  { value: "Directorship", label: "Directorship" },
  { value: "Employment", label: "Employment" },
  { value: "Family", label: "Family" },
  { value: "Identification", label: "Identification" },
  { value: "LegalEntity", label: "LegalEntity" },
  { value: "Membership", label: "Membership" },
  { value: "Occupancy", label: "Occupancy" },
  { value: "Organization", label: "Organization" },
  { value: "Ownership", label: "Ownership" },
  { value: "Passport", label: "Passport" },
  { value: "Person", label: "Person" },
  { value: "Position", label: "Position" },
  { value: "Representation", label: "Representation" },
  { value: "Sanction", label: "Sanction" },
  { value: "Security", label: "Security" },
  { value: "Thing", label: "Thing" },
  { value: "UnknownLink", label: "UnknownLink" },
  { value: "Vessel", label: "Vessel" },
];

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DEFAULT_PARAMS = {
  q: "",
  schema: "Thing",
  exclude_schema: [],
  exclude_dataset: [],
  countries: [],
  topics: [],
  datasets: [],
  dataset: "default",
  limit: 10,
  offset: 0,
  sort: [],
  target: null,
  fuzzy: true,
  simple: false,
};

const ColorEnum = {
  GRAY: "gray",
  RED: "red",
};

function camelCaseToCapitalCase(input) {
  // Add a space before each capital letter, then capitalize the first letter
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
}


const handleEmptyDownload = async (query, relevantflag, nonRelevantflag) => {
  try {
    const response = await Public.searchEntity(query);
    const searchData = response?.data?.responses?.match.results ?? [];
    const today = new Date().toLocaleDateString();

    const image = await generateBase64FromImage(
      `${window.location.origin}/images/Logo_horizontal.png`
    );

    const tableHeader = [
      { text: "Name", style: "tableHeader" },
      { text: "Entity Type", style: "tableHeader" },
      { text: "Target", style: "tableHeader" },
      { text: "Last Change", style: "tableHeader" },
      { text: "Flag Status", style: "tableHeader" },
    ];

    const tableBody = searchData.map((result) => [
      result.caption,
      result.schema,
      result.target ? "Yes" : "No",
      moment(result.last_change).format("YYYY-MM-DD HH:mm"),
      relevantflag[result.id] ? "Relevant" : nonRelevantflag[result.id] ? "Non-Relevant" : "N/A",
    ]);

    const headerContent = [
      {
        image,
        width: 150,
        alignment: "left",
        margin: [-40, -25, 10, 0],
      },
      {
        text: [
          { text: `info@synrgy.solutions\n` },
          { text: `Address: 10 Market Street, Unit 2090,\nCamana Bay, Cayman Islands KY19006\n\n` },
          { text: "Date: ", bold: true },
          { text: `${today}` },
        ],
        alignment: "right",
        margin: [0, -35, 10, 0],
      },
    ];

    const searchDetailsTable = {
      table: {
        widths: ['auto', '*'],
        body: [
          [{ text: 'Search Term', bold: true }, query.q.trim()],
          [{ text: 'Search Dataset', bold: true }, query.dataset],
          [{ text: 'Search Entity Type', bold: true }, query.schema],
          [{ text: 'Search Countries', bold: true }, query.countries],
          [{ text: 'Date of Search', bold: true }, moment(new Date().toISOString()).format("YYYY-MM-DD HH:mm")],
          [{ text: 'Results', bold: true }, Object.values(relevantflag).some(flag => flag)? 'There is positive match with search entity' : 'No positive match with search entity'],
        ]
      },
    };

    const content = [
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 10,
            w: 515,
            h: 100,
            lineWidth: 1,
            lineColor: "#000000",
          },
        ],
        absolutePosition: { x: 40, y: 40 },
      },
      {
        columns: headerContent,
        margin: [50, 50, 0, 20],
      },
      { text: "One-Off Screening Report", style: "header" },
      searchDetailsTable,
      { text: "Search Results", style: "subheader" },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*"],
          body: [tableHeader, ...tableBody],
        },
      },
    ];

    const docDefinition = {
      content,
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          fillColor: "#a5b8e8",
        },
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.download("search_report.pdf");
  } catch (error) {
    console.error("Error generating search report:", error);
  }
};

const generateBase64FromImage = (imageSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    img.src = imageSrc;
  });
};

export const handleDownload = async (record, query) => {
  try {
    const response = await Public.getSearchResultDb(record);
    const image = await generateBase64FromImage(
      `${window.location.origin}/images/Logo_horizontal.png`
    );
    const relevance =
      response?.data?.data &&
        response.data.data.length > 0 &&
        response.data.data[0]?.relevance !== undefined
        ? response.data.data[0].relevance
        : "N/A";
    const content = [
      {
        image,
        width: 100,
        alignment: "right",
      },
      { text: "Entity Details:", style: "header" },
      `Search Term: ${query.q.trim()}`,
      "_____________________________________________________________________________________",
      `Search Dataset: ${query.dataset}`,
      "_____________________________________________________________________________________",
      `Search Entity Type: ${query.schema}`,
      "_____________________________________________________________________________________",
      `Search Countries: ${query.countries}`,
      "_____________________________________________________________________________________",

      `Name: ${record.caption}`,
      "_____________________________________________________________________________________",
      `Entity Type: ${record.schema}`,
      "_____________________________________________________________________________________",
      `Target: ${record.target ? "Yes" : "No"}`,
      "_____________________________________________________________________________________",
      record.hitDate
        ? `Hit Date: ${moment(record.hitDate).format("YYYY-MM-DD HH:mm")}`
        : ``,
      record.hitDate
        ? "_____________________________________________________________________________________"
        : "",
      `First Seen: ${moment(record.first_seen).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Last Seen: ${moment(record.last_seen).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Last Change: ${moment(record.last_change).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Date of Report: ${moment(new Date().toISOString()).format(
        "YYYY-MM-DD HH:mm"
      )}`,
      "_____________________________________________________________________________________",
      `Relevance:${relevance}`,

      "_____________________________________________________________________________________",
      ...Object.keys(record.properties)
        .map((key) => [
          {
            text: `${camelCaseToCapitalCase(key)}: ${record.properties[key]}`,
            link: record.properties[key],
            margin: [0, 0, 0, 5],
          },
          "_____________________________________________________________________________________",
        ])
        .flat(),
    ];

    if (record.properties.position || record.properties.fatherName) {
      content.push(
        ...[
          "Relationships :",
          {
            table: {
              widths: ["*", "*"],
              body: [],
            },
          },
        ]
      );
    }

    if (record.properties.position) {
      content[content.length - 1].table.body.push([
        { text: "Positions occupied", bold: true },
        { text: record.properties.position },
      ]);
    }

    if (record.properties.fatherName) {
      content[content.length - 1].table.body.push([
        { text: "Father Name", bold: true },
        { text: record.properties.fatherName },
      ]);
    }
    if (record.properties.idNumber) {
      content[content.length - 1].table.body.push([
        { text: "Identifications number", bold: true },
        { text: record.properties.idNumber },
      ]);
    }

    content.push(
      "_____________________________________________________________________________________"
    );

    if (record.userNotes)
      content.push(
        ...[
          `Internal Notes: ${record.userNotes}`,
          "_____________________________________________________________________________________",
        ]
      );
    if (record.matchStatus)
      content.push(
        ...[
          `Match Status: ${record.matchStatus}`,
          "_____________________________________________________________________________________",
        ]
      );
    if (record.resolveDate)
      content.push(
        ...[
          `Resolve Date: ${moment(record.resolveDate).format(
            "YYYY-MM-DD HH:mm"
          )}`,
          "_____________________________________________________________________________________",
        ]
      );
    const docDefinition = {
      content,
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          decoration: "underline",
          margin: [0, 0, 0, 10],
        },
      },
    };

    // Generate the PDF
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Download the PDF
    pdfDocGenerator.download("record.pdf");
    console.log("relevance", response.data.data[0].relevance);
  } catch (error) {
    console.error("Error on pdf download", error);
  }
};

const SearchEntity = () => {
  const [data, setData] = useState([]);
  const [propertiesModal, setPropertiesModal] = useState(null);
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [datasets, setDatasets] = useState([]);
  const [internet, setInternet] = useState(false);
  const [internetResult, setInternetResult] = useState(null);
  const [debouncedParams, setDebouncedParams] = useState(DEFAULT_PARAMS);
  const [relevantflag, setReleventflag] = useState({})
  const [nonRelevantflag, setNonReleventflag] = useState({})

  const handleGoogle = async () => {
    try {
      const results = await User.googleSearch({ query: params.q });
      setInternetResult(results.data.result);
    } catch (error) {
      console.error("Error storing search result:", error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedParams(params);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [params]);

  useEffect(() => {
    Public.getOpenSanctionsCatalog().then(({ data }) => {
      setDatasets(data.datasets);
    });
  }, []);

  const [total, setTotal] = useState(0);

  const fetchData = useCallback(async () => {
    if (debouncedParams.q.trim() === "") return;
    const response = await Public.searchEntity(debouncedParams);
    setData(response?.data?.responses?.match.results ?? []);
    setTotal(
      Math.min(response?.data?.responses?.match.total?.value ?? 10, 9499)
    );
  }, [debouncedParams]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [graphModal, setGraphModal] = useState(false);
  const [graphLoading, setGraphLoading] = useState();
  const [graphData, setGraphData] = useState();
  const handleGraph = async (record) => {
    setGraphLoading(record.id);
    const data = await getNetworkGraphData(record.id);
    setGraphData(data);
    setGraphLoading();
    setGraphModal(true);
  };

  const handleGraphClose = () => {
    setGraphData();
    setGraphLoading();
    setGraphModal(false);
  };

  const handleRelevantFlag = async (record) => {
    try {
      console.log("handleRelevantFlag clicked...", record);
      const response = await Public.postSearchResult({
        query: debouncedParams.q,
        name: record.caption,
        recordId: record.id,
        relevance: "relevant",
      });
      setReleventflag((prevFlags) => ({
        ...prevFlags,
        [record.id]: true,
      }));
      if (nonRelevantflag[record.id]) {
        setNonReleventflag((prevFlags) => ({
          ...prevFlags,
          [record.id]: false,
        }));
      }
    } catch (error) {
      console.error("Error storing relevant item:", error);
    }
  };
  const handleNotRelevantFlag = async (record) => {
    try {
      console.log("handleNonnnnRelevantFlag", record);
      const response = await Public.postSearchResult({
        query: debouncedParams.q,
        name: record.caption,
        recordId: record.id,
        relevance: "non-relevant",
      });
      setNonReleventflag((prevFlags) => ({
        ...prevFlags,
        [record.id]: true,
      }));
      if (relevantflag[record.id]) {
        setReleventflag((prevFlags) => ({
          ...prevFlags,
          [record.id]: false,
        }));
      }
    } catch (error) {
      console.error("Error storing non-relevant item:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "caption",
      key: "caption",
    },
    {
      title: "Entity Type",
      dataIndex: "schema",
      key: "schema",
    },
    {
      title: "Details",
      dataIndex: "properties",
      key: "properties",
      render: (a) => (
        <>
          <Button onClick={() => setPropertiesModal(a)} type="link">
            Details
          </Button>
        </>
      ),
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      render: (a) => <>{a ? "Yes" : "No"}</>,
    },
    {
      title: "Last Change",
      dataIndex: "last_change",
      key: "last_change",
      render: (a) => (
        <div className="whitespace-nowrap">
          {a ? moment(a).format("YYYY-MM-DD HH:mm") : ""}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (a, record) => (
        <div className="whitespace-nowrap">
          <Button
            onClick={() => handleDownload(record, debouncedParams)}
            type="link"
          >
            Download
          </Button>
          <Button
            onClick={() => handleGraph(record)}
            type="link"
            loading={graphLoading === record.id}
          >
            Open Graph
          </Button>
        </div>
      ),
    },
    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      render: (text, record) => (
        <div className="whitespace-nowrap flex gap-2">
          <button
            onClick={() => handleRelevantFlag(record)}
            className={`relative flex items-center justify-center w-8 h-8 border-red`}
            style={{ padding: "2px" }}
          >
            {relevantflag[record.id] ? (
              <BsFillFlagFill className="absolute inset-0 w-6 h-6" style={{ color: "red" }} />
            ) : (
              <IoFlagOutline className="absolute inset-0 w-6 h-6" style={{ color: "red" }} />
            )}
          </button>
          <button
            onClick={() => handleNotRelevantFlag(record)}
            className={`relative flex items-center justify-center w-8 h-8 border-gray`}
            style={{ padding: "2px" }}
          >
            {nonRelevantflag[record.id] ? (
              <BsFillFlagFill className="absolute inset-0 w-6 h-6" style={{ color: "gray" }} />
            ) : (
              <IoFlagOutline className="absolute inset-0 w-6 h-6" style={{ color: "gray" }} />
            )}
          </button>
        </div>
      ),
    }
  ];

  const handleRelevant = async (item) => {
    try {
      const dataToStore = {
        query: params.q,
        searchResults: [
          {
            title: item.title,
            description: item.description,
            url: item.url,
            relevance: "relevant",
          },
        ],
      };
      const response = await User.searchResultStore(dataToStore);

      const storedResults = response.data;

      const updatedData = data.map((i) => {
        if (i.caption === item.title) {
          i.relevance = "relevant";
          i.color = ColorEnum.GRAY;
        }
        return i;
      });
      console.log("response", response);
      setData(updatedData);
    } catch (error) {
      console.error("Error storing relevant item:", error);
    }
  };
  const handleNonRelevant = async (item) => {
    try {
      const dataToStore = {
        query: params.q,
        searchResults: [
          {
            title: item.title,
            description: item.description,
            url: item.url,
            relevance: "non-relevant",
            query: item.q,
          },
        ],
      };
      console.log("items", item);
      const response = await User.searchResultStore(dataToStore);

      const storedResults = response.data;

      const updatedData = data.map((i) => {
        if (i.caption === item.title) {
          i.relevance = "non-relevant";
          i.color = ColorEnum.RED;
        }
        return i;
      });

      setData(updatedData);
    } catch (error) {
      console.error("Error storing non-relevant item:", error);
    }
  };

  const handleRelevantResultClick = async () => {
    try {
      const response = await Public.getRelevantResult({
        params: {
          q: debouncedParams.q,
        },
      });
      if (response.data.success) {
        const relevantResults = response.data.data;
        const tableBody = relevantResults.map((result, index) => [
          { text: (index + 1).toString() },
          { text: result.title },
          { text: result.description },
          { text: result.url },
        ]);
        const pdfContent = [
          { text: "Relevant Search Results:", style: "header" },
          `Search Term: ${debouncedParams.q.trim()}`,
          "_____________________________________________________________________________________",
          {
            table: {
              headerRows: 1,
              widths: ["10%", "20%", "35%", "25%"],
              body: [
                [
                  { text: "#", style: "tableHeader" },
                  { text: "Title", style: "tableHeader" },
                  { text: "Description", style: "tableHeader" },
                  { text: "Url", style: "url" },
                ],
                ...tableBody,
              ],
            },
          },
        ];
        const docDefinition = {
          content: pdfContent,
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              decoration: "underline",
              margin: [0, 0, 0, 10],
            },
            tableHeader: {
              bold: true,
              fontSize: 12,
              color: "black",
              fillColor: "#f2f2f2",
            },
          },
        };
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download("relevant_search_results.pdf");
      }
    } catch (error) {
      console.error("Error fetching relevant results:", error);
    }
  };
  return (
    <>
      <h3>Screen Entity</h3>
      <div className="p-4 ">
        <Space className="mb-2">
          <Input
            className="flex flex-grow"
            onChange={(e) =>
              setParams((prev) => ({ ...prev, q: e.target.value }))
            }
            placeholder="Search..."
          />
          <Button
            onClick={() => {
              setInternet(false);
              fetchData();
            }}
            type="primary"
          >
            Search Database
          </Button>
          <Button
            onClick={() => {
              setInternet(true);
              handleGoogle();
            }}
            type="link"
          >
            Search Internet
          </Button>
        </Space>
        <div className="my-3">
          <Space>
            <Space>
              <strong>Fuzzy</strong>
              <Switch
                checked={params.fuzzy}
                onChange={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    offset: 0,
                    limit: prev.limit,
                    fuzzy: e,
                  }))
                }
              />
            </Space>
            <Space className="ml-4">
              <strong>Simple</strong>
              <Switch
                checked={params.simple}
                onChange={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    offset: 0,
                    limit: prev.limit,
                    simple: e,
                  }))
                }
              />
            </Space>
          </Space>
        </div>
        <div className="my-3">
          <Space>
            <label>Dataset</label>
            <select
              value={params.dataset}
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  dataset: e.target.value,
                }))
              }
            >
              <option value="all">ALL</option>
              {datasets.map((dataset, index) => (
                <option value={dataset.name} key={index}>{dataset.title}</option>
              ))}
            </select>
          </Space>
        </div>
        <div className="my-3">
          <Space>
            <label>Entity Type</label>
            <select
              value={params.schema}
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  schema: e.target.value,
                }))
              }
            >
              {ENTITY_TYPES.map((type, index) => (
                <option value={type.value} key={index}>{type.label}</option>
              ))}
            </select>
          </Space>
        </div>
        <div className="my-3">
          <Space>
            <label>Nationalities</label>
            <Select
              value={params.countries}
              optionFilterProp="children"
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  countries: e,
                }))
              }
              mode="tags"
              style={{ width: 200 }}
            >
              {countries.map((c, index) => (
                <Select.Option key={index} value={c.code} >
                  {c.label}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </div>

        {internet ? (
          <div style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {internetResult?.knowledge_panel && (
                <>
                  <Card
                    title={
                      <>
                        <div>{internetResult?.knowledge_panel?.name}</div>
                        <div>
                          <p style={{ fontSize: 12 }}>
                            {internetResult?.knowledge_panel?.label}
                          </p>
                        </div>
                      </>
                    }
                    extra={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Avatar
                          src={internetResult?.knowledge_panel?.image.url}
                          size={100}
                          alt={internetResult?.knowledge_panel?.name}
                        />
                        <div>
                          <a
                            href={
                              internetResult?.knowledge_panel?.description?.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            More Info
                          </a>
                        </div>
                      </div>
                    }
                  >
                    <Text type="secondary">
                      {internetResult?.knowledge_panel?.description?.text}
                    </Text>
                    {internetResult?.knowledge_panel?.info.map?.(
                      (item, index) => (
                        <div key={index}>
                          <Title level={5}>{item.title}</Title>
                          {item.labels.map((label, index) => (
                            <Text key={index} type="secondary" block>
                              {label}
                            </Text>
                          ))}
                        </div>
                      )
                    )}
                  </Card>
                </>
              )}

              <Divider />
              <Button type="primary" onClick={handleRelevantResultClick}>
                Relevant search result Download
              </Button>

              <Divider />
              <h4>Search Results</h4>
              {/* Results */}
              <List
                itemLayout="vertical"
                dataSource={internetResult?.results ?? []}
                renderItem={(item) => (
                  <List.Item
                    key={item.title}
                    actions={[
                      <div className="flex justify-end gap-2">
                        <div
                          className="text-gray-500 "
                          onClick={() => handleNonRelevant(item)}
                        >
                          <BsFillFlagFill className="w-6 h-6 " />
                        </div>

                        <div
                          className="text-red-500"
                          onClick={() => handleRelevant(item)}
                        >
                          <BsFillFlagFill className="w-6 h-6" />
                        </div>
                      </div>,
                    ]}
                    extra={
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkOutlined />
                      </a>
                    }
                  >
                    <List.Item.Meta
                      title={
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.title}
                        </a>
                      }
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Space>
          </div>
        ) : (
          <>
            <Table dataSource={data} columns={columns} pagination={false} />
            {/* <Pagination
              current={params.offset / params.limit + 1}
              pageSize={params.limit}
              total={total}
              onChange={(page, pageSize) => {
                setParams((prev) => ({
                  ...prev,
                  offset: pageSize === prev.limit ? (page - 1) * pageSize : 0,
                  limit: pageSize,
                }));
              }}
            /> */}
          </>
        )}
      </div>

      {/* {data.length === 0 && ( */}
      <Button
        type="primary"
        onClick={() => handleEmptyDownload(debouncedParams, relevantflag, nonRelevantflag)}
      >
        Download Search Report
      </Button>
      {/* )} */}

      <Modal
        open={propertiesModal}
        onCancel={() => setPropertiesModal(null)}
        title="Details"
        footer={[
          <Button onClick={() => setPropertiesModal(null)}>Close</Button>,
        ]}
        destroyOnClose
      >
        {propertiesModal && <DynamicDescription data={propertiesModal} />}
      </Modal>

      <Modal
        open={graphModal}
        onCancel={handleGraphClose}
        title="Graph"
        footer={[<Button onClick={handleGraphClose}>Close</Button>]}
        destroyOnClose
        width={800}
      >
        <NetworkGraph nodes={graphData?.nodes} edges={graphData?.edges} />
      </Modal>
    </>
  );
};

export default SearchEntity;
