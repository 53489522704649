import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./pages/Landing";
import Auth from "./pages/auth";
import Contact from "./pages/contact";
import Dashboard from "./pages/dashboard";
import Legal from "./pages/legal";

const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rValue = queryParams.get("r");
    if (rValue) {
      localStorage.rValue = rValue;
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/home" element={<Landing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/app/*" element={<Dashboard />} />
        <Route path="/legal/*" element={<Legal />} />
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
